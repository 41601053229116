import { getTokenContract } from "../constants/contract";
import { readOnlyProvider } from "../constants/provider";
import { useEffect, useState } from "react";
import { ethers } from "ethers";

const useGetExecutionDelayInBlocks = () => {
  const [executionDelayInBlocks, setExecutionDelayInBlocks] = useState(0);

  const fetchExecutionDelay = async () => {
    try {
      const contract = getTokenContract(readOnlyProvider);
      const res = await contract.executionDelayInBlocks();
      setExecutionDelayInBlocks(ethers.toNumber(res));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchExecutionDelay();
  }, []);

  return executionDelayInBlocks;
};

export default useGetExecutionDelayInBlocks;
