import React from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";

const AcceptedProposalCard = ({
  title,
  proposalId,
  enqueueBlock,
  executionDelayInBlocks,
}) => {
  return (
    <Card className="m-2">
      <Card.Body style={{ paddingBottom: "30px" }}>
        {/* Add paddingBottom to create space */}
        <Card.Title style={{ textAlign: "center" }}>{title}</Card.Title>
        <OverlayTrigger
          placement="top" // Ensure the tooltip appears on top of the icon
          overlay={
            <Tooltip id={`tooltip-${proposalId}`}>
              Proposal ID: {proposalId}
            </Tooltip>
          }
        >
          <div
            style={{
              position: "absolute",
              bottom: "20px", // Keep the icon at the bottom but away from the text
              left: "15px",
              cursor: "pointer", // Show pointer cursor when hovering over the icon
              display: "flex", // Ensures that div takes proper space
              alignItems: "center",
              zIndex: 9999, // Ensure the icon is not obstructed
            }}
          >
            <FaInfoCircle style={{ color: "#6c757d", fontSize: "20px" }} />
          </div>
        </OverlayTrigger>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
            marginBottom: "10px",
            paddingTop: "10px",
            borderTop: "1px solid rgba(255, 255, 255, 0.1)",
          }}
        ></div>
        <Card.Text>
          Timelock expiration: Block {enqueueBlock + executionDelayInBlocks}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default AcceptedProposalCard;
