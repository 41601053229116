import React from "react";
import { Flip, ToastContainer } from "react-toastify";
import "@rainbow-me/rainbowkit/styles.css";

import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { mainnet } from "wagmi/chains";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

import ProposalsDashboard from "./ProposalsDashboard";

const config = getDefaultConfig({
  appName: process.env.REACT_APP_APP_NAME,
  projectId: process.env.REACT_APP_PROJECT_ID,
  chains: [mainnet],
});
const queryClient = new QueryClient();

function App() {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <div className="App">
            <ProposalsDashboard />
            <ToastContainer
              closeButton={false}
              draggable
              newestOnTop
              transition={Flip}
              pauseOnHover={false}
              pauseOnFocusLoss={false}
            />
          </div>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
