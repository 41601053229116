import { useCallback } from "react";
import { toast } from "react-toastify";

import { getTokenContract } from "../constants/contract";
import { getSigner } from "../helper";

const useDeleteProposal = (proposalId) => {
  return useCallback(async () => {
    const signer = await getSigner();
    const toastId = toast.loading("Sending...", {
      position: "top-right",
    });

    try {
      const contract = getTokenContract(signer);
      const tx = await contract.deleteProposal(proposalId);
      const receipt = await tx.wait();

      if (receipt.status) {
        toast.dismiss(toastId);
        return toast.success("Proposal deleted!", {
          position: "top-right",
        });
      } else {
        toast.dismiss(toastId);
        return toast.error("Proposal submission failed", {
          position: "top-right",
        });
      }
    } catch (err) {
      toast.dismiss(toastId);

      toast.error("ERROR WHILE DELETING PROPOSAL", {
        position: "top-right",
      });
    }
  }, [proposalId]);
};

export default useDeleteProposal;
