import { getTokenContract } from "../constants/contract";
import { readOnlyProvider } from "../constants/provider";
import { useEffect, useState, useCallback } from "react";
import { ethers } from "ethers";

const useFetchProposals = (counter) => {
  const [proposals, setProposals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchProposals = useCallback(async () => {
    try {
      setIsLoading(true);
      const contract = getTokenContract(readOnlyProvider);
      const proposalsList = [];

      for (let idx = 0; idx < counter; idx++) {
        const res = await contract.proposals(idx);
        const converted = {
          id: idx,
          proposer: res[0],
          title: res[1],
          deadlineBlock: ethers.toNumber(res[2]),
          enqueueBlock: ethers.toNumber(res[3]),
          executed: res[4],
          accepted: res[5],
          enqueued: res[6],
          terminatedWithRejection: res[7],
        };
        proposalsList.push(converted);
      }

      setProposals(proposalsList);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  }, [counter]);

  useEffect(() => {
    fetchProposals();
  }, [fetchProposals]);

  return [isLoading, proposals];
};

export default useFetchProposals;
