import { ethers } from "ethers";
import TokenABI from "./TokenABI.json";

export const getTokenContract = (providerOrSigner) => {
  return new ethers.Contract(
    process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS,
    TokenABI,
    providerOrSigner
  );
};
