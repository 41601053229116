import React from "react";
import { Card, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaTrashAlt, FaInfoCircle } from "react-icons/fa"; // Import icons
import { useAccount } from "wagmi";
import { useDeleteProposal, useEnqueueProposal } from "../hooks";

const WaitingProposalCard = ({ title, proposalId, proposer }) => {
  const account = useAccount();
  const deleteProposal = useDeleteProposal(proposalId);
  const enqueueProposal = useEnqueueProposal(proposalId);

  return (
    <Card className="m-2">
      <Card.Body style={{ paddingBottom: "15px" }}>
        <Card.Title style={{ textAlign: "center" }}>{title}</Card.Title>

        {/* Clickable Info Icon with Tooltip */}
        <OverlayTrigger
          placement="top" // Ensure the tooltip appears on top of the icon
          overlay={
            <Tooltip id={`tooltip-${proposalId}`}>
              Proposal ID: {proposalId}
            </Tooltip>
          }
        >
          <div
            style={{
              position: "absolute",
              bottom: "15px",
              left: "15px",
              cursor: "pointer", // Show pointer cursor when hovering over the icon
              display: "flex", // Ensures that div takes proper space
              alignItems: "center",
              zIndex: 9999, // Ensure the icon is not obstructed
            }}
          >
            <FaInfoCircle style={{ color: "#6c757d", fontSize: "20px" }} />
          </div>
        </OverlayTrigger>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
            marginBottom: "40px",
            paddingTop: "20px",
            borderTop: "1px solid rgba(255, 255, 255, 0.1)",
          }}
        ></div>

        {/* Enqueue button moved to the bottom center */}
        <div
          style={{
            display: "flex",
            justifyContent: "center", // Center the button horizontally
            position: "absolute", // Position it relative to the card
            bottom: "35px", // Place at the bottom of the card
            left: "50%", // Move to the horizontal center
            transform: "translateX(-50%)", // Center the button perfectly
            width: "100%", // Take the full width of the card
          }}
        >
          <Button
            className="m-2"
            style={{
              backgroundColor: "#23a6d5",
              border: "none", // Remove sharp border
              borderRadius: "5px", // Rounded corners
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Soft shadow
              transition: "box-shadow 0.3s ease, transform 0.3s ease", // Smooth hover effect
              padding: "10px 20px", // Consistent padding
              whiteSpace: "nowrap", // Prevent text wrapping
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.boxShadow =
                "0px 8px 16px rgba(0, 0, 0, 0.3)")
            } // Lift effect
            onMouseLeave={(e) =>
              (e.currentTarget.style.boxShadow =
                "0px 4px 12px rgba(0, 0, 0, 0.2)")
            }
            onClick={enqueueProposal}
          >
            Enqueue
          </Button>
        </div>

        {/* Trash icon at the bottom-right corner */}
        {account.address === proposer && (
          <Button
            variant="link"
            style={{
              position: "absolute",
              bottom: "15px",
              right: "15px", // Ensure it's aligned to the bottom-right
              background: "none",
              border: "none",
              padding: "0",
              boxShadow: "none",
              width: "auto", // Ensure the button doesn't stretch on mobile
            }}
            onClick={deleteProposal}
            onMouseEnter={(e) =>
              (e.currentTarget.style.transform = "scale(1.2)")
            }
            onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
          >
            <FaTrashAlt style={{ color: "#ff6b6b", fontSize: "20px" }} />
          </Button>
        )}
      </Card.Body>
    </Card>
  );
};

export default WaitingProposalCard;
