import Web3Modal from "web3modal";
import { ethers } from "ethers";

export const encodeFunctionData = (functionSignature, parameterValues) => {
  // Split functionSignature to get the function name and types
  const functionName = functionSignature.split("(")[0];
  const paramTypes = functionSignature
    .substring(
      functionSignature.indexOf("(") + 1,
      functionSignature.indexOf(")")
    )
    .split(",");

  console.log("Function name:", functionName);
  console.log("Parameter types:", paramTypes);

  // Construct the function call data using ethers.js
  const iface = new ethers.Interface([
    `function ${functionName}(${paramTypes.join(",")})`,
  ]);

  const encodedData = iface.encodeFunctionData(functionName, parameterValues);

  console.log("Encoded Function Data:", encodedData);
  return encodedData;
};

export const getSigner = async () => {
  const web3Modal = new Web3Modal();
  const instance = await web3Modal.connect();
  const provider = new ethers.BrowserProvider(instance);
  const signer = await provider.getSigner();

  return signer;
};
