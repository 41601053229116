import { useCallback } from "react";
import { toast } from "react-toastify";

import { getTokenContract } from "../constants/contract";
import { getSigner } from "../helper";

const useExecuteProposal = (proposalId) => {
  return useCallback(async () => {
    const signer = await getSigner();
    const toastId = toast.loading("Sending...", {
      position: "top-right",
    });

    try {
      const contract = getTokenContract(signer);
      const tx = await contract.executeProposal(proposalId);
      const receipt = await tx.wait();

      if (receipt.status) {
        toast.dismiss(toastId);
        return toast.success("Proposal executed!", {
          position: "top-right",
        });
      } else {
        toast.dismiss(toastId);
        return toast.error("Proposal execution failed!", {
          position: "top-right",
        });
      }
    } catch (err) {
      toast.dismiss(toastId);

      toast.error("ERROR EXECUTING PROPOSAL", {
        position: "top-right",
      });
    }
  }, [proposalId]);
};

export default useExecuteProposal;
