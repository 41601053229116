import { useCallback } from "react";
import { toast } from "react-toastify";

import { getTokenContract } from "../constants/contract";
import { getSigner } from "../helper";

const useCreateProposal = (
  title,
  target,
  data,
  values,
  votingDurationInBlocks
) => {
  return useCallback(async () => {
    const signer = await getSigner();
    const toastId = toast.loading("Sending...", {
      position: "top-right",
    });

    try {
      const contract = getTokenContract(signer);
      console.log(title, target, data, values, votingDurationInBlocks);
      console.log(await contract.getAddress());
      const tx = await contract.createProposal(
        title,
        target,
        data,
        values,
        votingDurationInBlocks
      );
      const receipt = await tx.wait();

      if (receipt.status) {
        toast.dismiss(toastId);
        return toast.success("Proposal submited!", {
          position: "top-right",
        });
      } else {
        toast.dismiss(toastId);
        return toast.error("Proposal submission failed", {
          position: "top-right",
        });
      }
    } catch (err) {
      toast.dismiss(toastId);

      toast.error("OOPS!! ERROR WHILE CREATING PROPOSAL", {
        position: "top-right",
      });
    }
  }, [title, target, data, values, votingDurationInBlocks]);
};

export default useCreateProposal;
