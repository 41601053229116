import React, { useEffect, useState } from "react";
import {
  Card,
  CloseButton,
  Button,
  Row,
  Col,
  Container,
  Accordion,
  Table,
  Tab,
  Tabs,
  Nav,
  Spinner,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useBlockNumber } from "wagmi";
import "./ProposalsDashboard.css";
import { getTokenContract } from "./constants/contract";
import { readOnlyProvider } from "./constants/provider";

import {
  OpenProposalCard,
  WaitingProposalCard,
  AcceptedProposalCard,
  ExecutableProposalCard,
  PastProposalCard,
} from "./components";
import {
  useGetProposalIdCounter,
  useGetExecutionDelayInBlocks,
  useFetchProposals,
  useCreateProposal,
} from "./hooks";
import { encodeFunctionData } from "./helper";

const ProposalsDashboard = () => {
  const counter = useGetProposalIdCounter();
  const [isLoading, proposals] = useFetchProposals(counter);
  const executionDelayInBlocks = useGetExecutionDelayInBlocks();
  const currentBlockNumber = useBlockNumber().data;

  const [title, setTitle] = useState("");
  const [votingDuration, setVotingDuration] = useState("0");
  const [contract, setContract] = useState("");
  const [params, setParams] = useState([]);
  const [ethValue, setEthValue] = useState("0");
  const [functionName, setFunctionName] = useState(""); // Rename from functionSignature to functionName
  const [actions, setActions] = useState([]);

  const [openProposals, setOpenProposals] = useState([]);
  const [waitingProposals, setWaitingProposals] = useState([]);
  const [acceptedProposals, setAcceptedProposals] = useState([]);
  const [executableProposals, setExecutableProposals] = useState([]);
  const [pastProposals, setPastProposals] = useState([]);

  useEffect(() => {
    const filteredProposals = proposals.filter(
      (proposal) => proposal.proposer !== ethers.ZeroAddress
    );

    setOpenProposals(
      filteredProposals.filter(
        (proposal) => proposal.deadlineBlock >= currentBlockNumber
      )
    );

    setWaitingProposals(
      filteredProposals.filter(
        (proposal) =>
          proposal.deadlineBlock < currentBlockNumber &&
          proposal.enqueued === false &&
          proposal.executed === false &&
          proposal.terminatedWithRejection === false
      )
    );

    setAcceptedProposals(
      filteredProposals.filter(
        (proposal) =>
          proposal.enqueued === true &&
          proposal.executed === false &&
          currentBlockNumber < proposal.enqueueBlock + executionDelayInBlocks
      )
    );

    setExecutableProposals(
      filteredProposals.filter(
        (proposal) =>
          proposal.enqueued === true &&
          proposal.executed === false &&
          currentBlockNumber >= proposal.enqueueBlock + executionDelayInBlocks
      )
    );

    setPastProposals(
      filteredProposals.filter(
        (proposal) =>
          proposal.executed === true ||
          proposal.terminatedWithRejection === true
      )
    );
  }, [proposals, currentBlockNumber, executionDelayInBlocks]);

  const handleAddAction = () => {
    // Validation
    if (!ethers.isAddress(contract)) {
      return toast.error("Wrong contract address!", {
        position: "top-right",
      });
    }

    if (functionName.trim().length === 0) {
      return toast.error("Function Name is required!", {
        position: "top-right",
      });
    }

    // Check if parameter types are valid
    const paramTypes = params.map((p) => p.type.trim()).filter(Boolean);
    if (
      paramTypes.length === 0 ||
      params.some((p) => !p.type.trim() || !p.value.trim())
    ) {
      return toast.error("Invalid function parameter types or values!", {
        position: "top-right",
      });
    }

    // Build functionSignature
    const functionSignature = `${functionName}(${paramTypes.join(",")})`;

    // Prepare params values for encoding
    const paramValues = params.map((p) => p.value);

    // Encode function data
    const encodedData = encodeFunctionData(functionSignature, paramValues);

    setActions([
      ...actions,
      {
        contract,
        functionSignature,
        params: JSON.stringify(paramValues), // Use values, as we already have types in the signature
        encodedData,
        ethValue,
      },
    ]);

    // Reset the form fields after adding the action
    setContract("");
    setFunctionName("");
    setParams([]);
    setEthValue("0");

    return toast.success("Action added!", {
      position: "top-right",
    });
  };

  const handleAddParam = () => {
    setParams([...params, { type: "", value: "" }]); // Add a new param with empty type and value
  };

  const handleParamChange = (index, field, value) => {
    const newParams = [...params];
    newParams[index][field] = value; // Update the param's type or value
    setParams(newParams);
  };

  const handleParamDelete = (idx) => {
    setParams(params.filter((_, index) => index !== idx));
  };

  const handleDelete = (idx) => {
    setActions(actions.filter((_, index) => index !== idx));
    return toast.success("Action deleted!", {
      position: "top-right",
    });
  };

  const handleProposalSubmit = useCreateProposal(
    title,
    actions.map((action) => action.contract),
    actions.map((action) => action.encodedData),
    actions.map((action) => ethers.parseEther(action.ethValue)),
    votingDuration
  );

  const handleSubmit = async () => {
    if (title.trim().length === 0) {
      return toast.error("Title should not be empty!", {
        position: "top-right",
      });
    }

    if (title.length > 50) {
      return toast.error("Title cannot be longer than 50 characters!", {
        position: "top-right",
      });
    }

    const contract = getTokenContract(readOnlyProvider);

    // Call the contract's view function to get the minimum voting duration
    const minDuration = await contract.minimumVotingDurationBlocks();

    // Convert the fetched value to a number if needed (depends on how it's stored in your contract)
    const minVotingDuration = ethers.toNumber(minDuration);

    // Validate if votingDuration is greater than or equal to the minimum duration
    if (Number(votingDuration) < minVotingDuration) {
      return toast.error(
        `Voting duration must be at least ${minVotingDuration} blocks!`,
        {
          position: "top-right",
        }
      );
    }

    if (actions.length === 0) {
      return toast.error("No Actions!", {
        position: "top-right",
      });
    }

    await handleProposalSubmit();
  };

  return (
    <Container fluid className="content">
      <Container className="mt-4 content">
        {/* Header */}
        <Row className="align-items-center mb-3">
          {/* Logo and Title */}
          <Col className="d-flex align-items-center">
            <img
              src="/logo_192x192.png"
              alt="Logo"
              style={{
                height: "40px",
                marginRight: "10px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              }} // Adjust the height and spacing as needed
            />
            <h2 className="vote-interface-title">$VOTE Interface</h2>
          </Col>

          {/* Buy $VOTE and Connect Wallet Buttons */}
          <Col className="d-flex justify-content-end align-items-center">
            <div className="d-flex button-container">
              <Button
                variant="primary"
                className="equal-width-btn me-2" // Equal width for both buttons
                style={{
                  backgroundColor: "#8ab054",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  border: "none",
                }}
                onClick={() => window.open("https://app.uniswap.org/swap?outputCurrency=0x158A77419B7fD5c7589590e7E068daC176A12727&chain=ethereum")}
              >
                Buy $VOTE
              </Button>
              <div className="equal-width-btn">
                <ConnectButton />
              </div>
            </div>
          </Col>
        </Row>

        <hr className="custom-separator" />

        {/* Content */}
        <div className="tabs-container">
          <Tabs defaultActiveKey="browse" className="mb-4 custom-tabs">
            <Tab eventKey="browse" title="Browse Proposals">
              <Accordion defaultActiveKey={["0"]} className="mb-4" alwaysOpen>
                <Accordion.Item eventKey="0" className="mb-4">
                  <Accordion.Header>Open proposals</Accordion.Header>
                  <Accordion.Body className="scrollable-row">
                    {openProposals.length > 0 ? (
                      openProposals.map((proposal, idx) => (
                        <OpenProposalCard
                          key={"Open Proposals" + idx}
                          title={proposal.title}
                          proposalId={proposal.id}
                          proposer={proposal.proposer}
                        />
                      ))
                    ) : isLoading ? (
                      <Spinner />
                    ) : (
                      <p className="no-proposals-text">No open proposals.</p>
                    )}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1" className="mb-4">
                  <Accordion.Header>
                    Proposals ready to be enqueued
                  </Accordion.Header>
                  <Accordion.Body className="scrollable-row">
                    {waitingProposals.length > 0 ? (
                      waitingProposals.map((proposal, idx) => (
                        <WaitingProposalCard
                          key={"Waiting Proposals" + idx}
                          title={proposal.title}
                          proposalId={proposal.id}
                          proposer={proposal.proposer}
                        />
                      ))
                    ) : isLoading ? (
                      <Spinner />
                    ) : (
                      <p className="no-proposals-text">
                        No proposals waiting for execution.
                      </p>
                    )}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2" className="mb-4">
                  <Accordion.Header>
                    Accepted and enqueued for execution
                  </Accordion.Header>
                  <Accordion.Body className="scrollable-row">
                    {acceptedProposals.length > 0 ? (
                      acceptedProposals.map((proposal, idx) => (
                        <AcceptedProposalCard
                          key={"Accepted Proposals" + idx}
                          title={proposal.title}
                          proposalId={proposal.id}
                          enqueueBlock={proposal.enqueueBlock}
                          executionDelayInBlocks={executionDelayInBlocks}
                        />
                      ))
                    ) : isLoading ? (
                      <Spinner />
                    ) : (
                      <p className="no-proposals-text">
                        No accepted proposals.
                      </p>
                    )}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3" className="mb-4">
                  <Accordion.Header>Executable proposals</Accordion.Header>
                  <Accordion.Body className="scrollable-row">
                    {executableProposals.length > 0 ? (
                      executableProposals.map((proposal, idx) => (
                        <ExecutableProposalCard
                          key={"Executable Proposals" + idx}
                          title={proposal.title}
                          proposalId={proposal.id}
                        />
                      ))
                    ) : isLoading ? (
                      <Spinner />
                    ) : (
                      <p className="no-proposals-text">
                        No executable proposals.
                      </p>
                    )}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4" className="mb-4">
                  <Accordion.Header>Past Proposals</Accordion.Header>
                  <Accordion.Body className="scrollable-row">
                    {pastProposals.length > 0 ? (
                      pastProposals.map((proposal, idx) => (
                        <PastProposalCard
                          key={"Past Proposals" + idx}
                          proposalId={proposal.id}
                          title={proposal.title}
                          accepted={proposal.accepted}
                        />
                      ))
                    ) : isLoading ? (
                      <Spinner />
                    ) : (
                      <p className="no-proposals-text">No past proposals.</p>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Tab>
            <Tab eventKey="create" title="Create Proposal">
              {/* Propose New Proposal*/}
              <h3 className="propose-title">Create Proposal</h3>
              <Row>
                <Col>
                  <Card className="mb-4 shadow">
                    <Card.Body>
                      <Card.Title style={{ marginBottom: "20px" }}>
                        Enter Proposal Details
                      </Card.Title>
                      <Card.Text>
                        <label>Title:</label>
                        <input
                          type="text"
                          className="form-control mb-3"
                          placeholder="Enter Title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                        <label>Voting Duration (in blocks):</label>
                        <input
                          type="number"
                          className="form-control mb-3"
                          placeholder="Enter Voting Duration in Blocks"
                          value={votingDuration}
                          min={0}
                          onChange={(e) => {
                            setVotingDuration(
                              "" + Number.parseInt(e.target.value)
                            );
                          }}
                        />
                      </Card.Text>

                      {actions.length > 0 && (
                        <>
                          <h5>Actions</h5>
                          <Table
                            striped
                            bordered
                            hover
                            responsive
                            className="actions-table"
                          >
                            <thead className="table-primary">
                              <tr>
                                <th style={{ whiteSpace: "nowrap" }}>#</th>
                                <th style={{ whiteSpace: "nowrap" }}>
                                  Contract Address
                                </th>
                                <th style={{ whiteSpace: "nowrap" }}>
                                  Function Signature
                                </th>
                                <th style={{ whiteSpace: "nowrap" }}>
                                  Function Parameters
                                </th>
                                <th style={{ whiteSpace: "nowrap" }}>
                                  Eth Value
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {actions.map((action, idx) => (
                                <tr key={idx}>
                                  <td>{idx + 1}</td>
                                  <td>{action.contract}</td>
                                  <td>{action.functionSignature}</td>
                                  <td>{action.params}</td>
                                  <td>{action.ethValue}</td>
                                  <td>
                                    <Button
                                      variant="danger"
                                      onClick={() => handleDelete(idx)}
                                    >
                                      Delete
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </>
                      )}

                      {/* Contract Address and Function Inputs */}
                      <Card className="p-3 mb-4">
                        <Card.Title>Add Action</Card.Title>
                        <Card.Body>
                          <Row>
                            <Col xs={12} md={4}>
                              <label>Contract Address:</label>
                              <input
                                type="text"
                                className="form-control mb-3"
                                placeholder="Enter Contract Address"
                                value={contract}
                                onChange={(e) => setContract(e.target.value)}
                              />
                            </Col>

                            <Col xs={12} md={4}>
                              <label>Function Name:</label>
                              <input
                                type="text"
                                className="form-control mb-3"
                                placeholder="Enter Function Name"
                                value={functionName}
                                onChange={(e) =>
                                  setFunctionName(e.target.value)
                                }
                              />
                            </Col>

                            <Col xs={12} md={4}>
                              <label>ETH Value:</label>
                              <input
                                type="number"
                                className="form-control mb-3"
                                placeholder="Enter ETH Value"
                                value={ethValue}
                                min="0"
                                onChange={(e) => setEthValue(e.target.value)}
                              />
                            </Col>
                          </Row>

                          <h5>Function Parameters:</h5>
                          {params.map((param, index) => (
                            <Row key={index} className="mb-3">
                              <Col xs={6}>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={`Type`}
                                  value={param.type}
                                  onChange={(e) =>
                                    handleParamChange(
                                      index,
                                      "type",
                                      e.target.value
                                    )
                                  }
                                />
                              </Col>
                              <Col
                                xs={6}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={`Value`}
                                  value={param.value}
                                  onChange={(e) =>
                                    handleParamChange(
                                      index,
                                      "value",
                                      e.target.value
                                    )
                                  }
                                />
                                <CloseButton
                                  variant="white"
                                  style={{ marginLeft: "10px" }}
                                  onClick={(e) => handleParamDelete(index)}
                                />
                              </Col>
                            </Row>
                          ))}

                          {/* Add Parameter and Add Action Buttons */}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between", // Push Add Parameter to left, Add Action to right
                              marginTop: "20px",
                            }}
                          >
                            <Button
                              variant="outline-primary"
                              onClick={handleAddParam}
                            >
                              Add Parameter
                            </Button>

                            <Button variant="success" onClick={handleAddAction}>
                              Add
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>

                      {/* Publish Proposal Button at the bottom */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end", // Align to the right
                          marginTop: "20px", // Add space above the button
                        }}
                      >
                        <Button variant="primary" onClick={handleSubmit}>
                          Publish Proposal
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </div>
      </Container>

      {/* Footer */}
      <footer style={{ width: "70%", textAlign: "center", margin: "0 auto" }}>
        <Row className="align-items-top mb-3">
          <Col>
            <h2 className="footer-title">About $VOTE</h2>
            <hr className="custom-separator" />
            <Nav className="flex-column">
              <Nav.Link href="https://votely.tech" className="text-white">
                Home page
              </Nav.Link>
              <Nav.Link href="https://www.dextools.io/app/en/ether/pair-explorer/0xa59946077fdd9bfd2bdb46b16be68c4efa085a97" className="text-white">
                Dextools chart
              </Nav.Link>
              <Nav.Link href="https://app.uniswap.org/swap?outputCurrency=0x158A77419B7fD5c7589590e7E068daC176A12727&chain=ethereum" className="text-white">
                Buy $VOTE
              </Nav.Link>
            </Nav>
          </Col>
          <Col>
            <h2 className="footer-title">ERC246 Standard</h2>
            <hr className="custom-separator" />
            <Nav className="flex-column">
              <Nav.Link
                href="https://github.com/vote-erc246/ERC-246"
                className="text-white"
              >
                Github
              </Nav.Link>
              <Nav.Link
                href="https://medium.com/@team.vote.erc/erc246-a-new-token-standard-with-built-in-governance-79f9c51b1b43"
                className="text-white"
              >
                Medium
              </Nav.Link>
            </Nav>
          </Col>
          <Col>
            <h2 className="footer-title">Socials</h2>
            <hr className="custom-separator" />
            <Nav className="flex-column">
              <Nav.Link
                href="https://x.com/votelyERC246"
                className="text-white"
              >
                X
              </Nav.Link>
              <Nav.Link href="https://t.me/votelyERC246" className="text-white">
                Telegram
              </Nav.Link>
            </Nav>
          </Col>
        </Row>
      </footer>
    </Container>
  );
};

export default ProposalsDashboard;
