import React from "react";
import { Card, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";

const PastProposalCard = ({ title, proposalId, accepted }) => {
  return (
    <Card className="m-2">
      <Card.Body>
        <Card.Title style={{ textAlign: "center" }}>{title}</Card.Title>
        <OverlayTrigger
          placement="top" // Ensure the tooltip appears on top of the icon
          overlay={
            <Tooltip id={`tooltip-${proposalId}`}>
              Proposal ID: {proposalId}
            </Tooltip>
          }
        >
          <div
            style={{
              position: "absolute",
              bottom: "20px", // Keep the icon at the bottom but away from the text
              left: "15px",
              cursor: "pointer", // Show pointer cursor when hovering over the icon
              display: "flex", // Ensures that div takes proper space
              alignItems: "center",
              zIndex: 9999, // Ensure the icon is not obstructed
            }}
          >
            <FaInfoCircle style={{ color: "#6c757d", fontSize: "20px" }} />
          </div>
        </OverlayTrigger>
        <div
          style={{
            position: "absolute",
            bottom: "20px", // Fixed distance from the bottom
            right: "15px", // Align to the right
          }}
        >
          <Badge bg={accepted ? "success" : "danger"}>
            {accepted === true ? "Accepted" : "Rejected"}
          </Badge>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
            marginBottom: "10px",
            paddingTop: "10px",
          }}
        ></div>
      </Card.Body>
    </Card>
  );
};

export default PastProposalCard;
