import React from "react";
import { Card, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useExecuteProposal } from "../hooks";
import { FaInfoCircle } from "react-icons/fa";

const ExecutableProposalCard = ({ title, proposalId }) => {
  const executeProposal = useExecuteProposal(proposalId);

  return (
    <Card className="m-2">
      <Card.Body>
        <Card.Title style={{ textAlign: "center" }}>{title}</Card.Title>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
            marginBottom: "10px",
            paddingTop: "10px",
            borderTop: "1px solid rgba(255, 255, 255, 0.1)",
          }}
        ></div>
        <OverlayTrigger
          placement="top" // Ensure the tooltip appears on top of the icon
          overlay={
            <Tooltip id={`tooltip-${proposalId}`}>
              Proposal ID: {proposalId}
            </Tooltip>
          }
        >
          <div
            style={{
              position: "absolute",
              bottom: "20px", // Keep the icon at the bottom but away from the text
              left: "15px",
              cursor: "pointer", // Show pointer cursor when hovering over the icon
              display: "flex", // Ensures that div takes proper space
              alignItems: "center",
              zIndex: 9999, // Ensure the icon is not obstructed
            }}
          >
            <FaInfoCircle style={{ color: "#6c757d", fontSize: "20px" }} />
          </div>
        </OverlayTrigger>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            variant="primary"
            className="me-2"
            onClick={executeProposal}
            style={{
              backgroundColor: "#23a6d5",
              border: "none",
              borderRadius: "5px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
              transition: "box-shadow 0.3s ease, transform 0.3s ease",
              padding: "10px 20px",
              whiteSpace: "nowrap",
            }}
          >
            Execute
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
            marginBottom: "10px",
            paddingTop: "10px",
          }}
        ></div>
      </Card.Body>
    </Card>
  );
};

export default ExecutableProposalCard;
