import React from "react";
import { Card, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";

import { useDeleteProposal } from "../hooks";
import { getSigner } from "../helper";
import { getTokenContract } from "../constants/contract";

import { FaTrashAlt, FaInfoCircle } from "react-icons/fa";

const OpenProposalCard = ({ title, proposalId, proposer }) => {
  const deleteProposal = useDeleteProposal(proposalId);
  const account = useAccount();

  const handleVote = async (support) => {
    const signer = await getSigner();
    const toastId = toast.loading("Sending...", {
      position: "top-right",
    });

    try {
      const contract = getTokenContract(signer);
      const tx = await contract.vote(proposalId, support);
      const receipt = await tx.wait();

      if (receipt.status) {
        toast.dismiss(toastId);
        return toast.success("Vote succeed!", {
          position: "top-right",
        });
      } else {
        toast.dismiss(toastId);
        return toast.error("Vote failed", {
          position: "top-right",
        });
      }
    } catch (err) {
      toast.dismiss(toastId);

      toast.error("ERROR WHILE VOTING", {
        position: "top-right",
      });
    }
  };

  return (
    <Card className="m-2">
      <Card.Body>
        <Card.Title style={{ textAlign: "center" }}>{title}</Card.Title>
        <OverlayTrigger
          placement="top" // Position the tooltip
          overlay={
            <Tooltip id={`tooltip-${proposalId}`}>
              Proposal ID: {proposalId}
            </Tooltip>
          }
        >
          <div
            style={{
              position: "absolute",
              bottom: "15px", // Adjust position to bottom left
              left: "15px",
              cursor: "pointer", // Pointer cursor on hover
              zIndex: 9999, // Ensure icon is not obstructed
            }}
          >
            <FaInfoCircle style={{ color: "#6c757d", fontSize: "20px" }} />
          </div>
        </OverlayTrigger>

        {/* Vote buttons placed at the bottom */}
        <div
          style={{
            display: "flex",
            justifyContent: "center", // Center buttons horizontally
            alignItems: "center",
            flexWrap: "wrap", // Allow buttons to wrap on smaller screens
            gap: "10px", // Add some space between buttons when they wrap
            marginTop: "20px",
            paddingTop: "20px",
            borderTop: "1px solid rgba(255, 255, 255, 0.1)",
          }}
        >
          <Button
            className="m-2"
            style={{
              flex: "1 1 auto", // Allow buttons to resize naturally
              minWidth: "120px",
              maxWidth: "200px", // Ensure buttons don't stretch too much on large screens
              backgroundColor: "#23d5ab",
              border: "none",
              borderRadius: "5px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
              transition: "box-shadow 0.3s ease, transform 0.3s ease",
              textAlign: "center",
              padding: "10px 20px",
              whiteSpace: "nowrap", // Prevent text wrapping
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.boxShadow =
                "0px 8px 16px rgba(0, 0, 0, 0.3)")
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.boxShadow =
                "0px 4px 12px rgba(0, 0, 0, 0.2)")
            }
            onClick={() => handleVote(true)}
          >
            Vote in favor
          </Button>

          <Button
            className="m-2"
            style={{
              flex: "1 1 auto", // Allow buttons to resize naturally
              minWidth: "120px",
              maxWidth: "200px", // Ensure buttons don't stretch too much on large screens
              backgroundColor: "#e73c7e",
              border: "none",
              borderRadius: "5px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
              transition: "box-shadow 0.3s ease, transform 0.3s ease",
              textAlign: "center",
              padding: "10px 20px",
              whiteSpace: "nowrap", // Prevent text wrapping
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.boxShadow =
                "0px 8px 16px rgba(0, 0, 0, 0.3)")
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.boxShadow =
                "0px 4px 12px rgba(0, 0, 0, 0.2)")
            }
            onClick={() => handleVote(false)}
          >
            Vote against
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "20px",
          }}
        ></div>

        {/* Trash icon at the bottom-right corner */}
        {account.address === proposer && (
          <Button
            variant="link"
            style={{
              position: "absolute",
              bottom: "15px",
              right: "15px", // Ensure it's aligned to the bottom-right
              background: "none",
              border: "none",
              padding: "0",
              boxShadow: "none",
              width: "auto", // Ensure the button doesn't stretch on mobile
            }}
            onClick={deleteProposal}
            onMouseEnter={(e) =>
              (e.currentTarget.style.transform = "scale(1.2)")
            }
            onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
          >
            <FaTrashAlt style={{ color: "#ff6b6b", fontSize: "20px" }} />
          </Button>
        )}
      </Card.Body>
    </Card>
  );
};

export default OpenProposalCard;
