import { getTokenContract } from "../constants/contract";
import { readOnlyProvider } from "../constants/provider";
import { useEffect, useState } from "react";
import { ethers } from "ethers";

const useGetProposalIdCounter = () => {
  const [counter, setCounter] = useState(0);

  const fetchCounter = async () => {
    try {
      const contract = getTokenContract(readOnlyProvider);
      const res = await contract.proposalIdCounter();
      setCounter(ethers.toNumber(res));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchCounter();
  }, []);

  return counter;
};

export default useGetProposalIdCounter;
